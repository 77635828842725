import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useProfile } from "components/ProfileProvider";
import { sendRequest } from "network";

import Spinner from "components/Spinner";

type RegisterResult = { id: string; email: string };

export default function MiniRegister({
  onClose,
  className = "",
}: {
  onClose?: Function;
  [n: string]: any;
}) {
  const navigate = useNavigate();
  const profile = useProfile();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const { isLoading, error, mutate } = useMutation(
    (p: any) => sendRequest<RegisterResult>("/api/sys-register", p),
    {
      onSuccess(r) {
        profile.setState({ id: r.id, email: r.email, password });
        navigate("/");
      },
    },
  );

  const validEmail = /^[^@\s]+@[^@\s]+\.[^@.\s]+$/.test(email);
  const validPwd = password?.length >= 8 && password === password2;

  const handleSubmit = e => {
    e.preventDefault();
    if (validEmail && validPwd) mutate({ email, password });
  };

  return (
    <div
      className={
        "flex flex-col-reverse w-full max-w-2xl gap-4 p-4 mx-auto mt-8 mb-4 sm:items-center sm:flex-row " +
        className
      }
    >
      <ul className="flex-1 space-y-1 leading-relaxed list-disc">
        <li>下載數以千計的獨家內容。</li>
        <li>您可以直接下載文件供私人使用。</li>
        <li>付款由受信任的第三方處理。</li>
      </ul>
      <form
        onSubmit={e => e.preventDefault()}
        className="flex flex-col items-stretch flex-1 max-w-full gap-2 py-4 sm:pl-8 sm:border-l sm:w-32 sm:border-lines"
      >
        <h2 className="mb-4 text-3xl text-center">註冊</h2>
        <input
          type="email"
          className="p-0.5 px-2 border rounded bg-paper border-lines"
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder="郵箱"
          autoFocus
        />
        <input
          type="password"
          className="p-0.5 px-2 border rounded bg-paper border-lines"
          value={password}
          onChange={e => setPassword(e.target.value)}
          placeholder="密碼"
        />
        {password && password.length < 8 ? (
          <div className="text-crimson error-icon">密碼需要 8 個字或以上</div>
        ) : null}
        {password && password2 && password !== password2 ? (
          <div className="text-crimson error-icon">兩個密碼必須一毛一樣</div>
        ) : null}
        <input
          type="password"
          className="p-0.5 px-2 border rounded bg-paper border-lines"
          value={password2}
          onChange={e => setPassword2(e.target.value)}
          placeholder="再輸密碼"
        />
        <button
          onClick={handleSubmit}
          className="p-0.5 px-2 rounded base-btn bg-crimson text-paper disabled:bg-stone-300"
          disabled={(email && (!validEmail || !validPwd)) || isLoading}
        >
          {isLoading ? <Spinner className="w-4 mx-auto" /> : "註冊"}
        </button>
        {error ? (
          <div className="mb-1 text-center text-crimson error-icon">
            {error === "none" ? "郵箱已被用" : error["message"] || error}
          </div>
        ) : null}
      </form>
    </div>
  );
}
