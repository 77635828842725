import { useState, useRef, Fragment, MouseEvent } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useHeadTitle } from "utils";
import { sendRequest } from "network";

import Categories from "components/Categories";
import Tile from "./Tile";

import type { Search } from "network";
import Spinner from "components/Spinner";
import Dialog from "components/Dialog";
import Item from "components/Item";
import MiniRegister from "components/MiniRegister";
import Reveal from "components/Reveal";

export default function Home() {
  useHeadTitle("易密");

  const navigate = useNavigate();
  const [params] = useSearchParams();

  const group = params.get("group");
  const search = params.get("q");
  const showRegister = params.get("register");

  //only used to create an embeddable link for SEO following
  const _page = params.get("page");

  const lastThrottled = useRef(0);

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    error,
  } = useInfiniteQuery({
    queryKey: ["books", search, group, _page],
    queryFn: async ({ pageParam = "" }) => {
      let since = Date.now() - lastThrottled.current;
      if (since < 1000) {
        await new Promise(ok => setTimeout(ok, 1000 - since));
      }
      lastThrottled.current = Date.now();
      return await sendRequest<Search>("/api/dropbox-search", {
        q: search || "",
        fd: group || "",
        cursor: pageParam || _page || "",
      });
    },
    getNextPageParam: prev => prev.cursor,
    onSuccess: res => {
      lastThrottled.current = Date.now();
      if (res.pages?.[0]?.error === "!accessToken") navigate("/admin");
    },
  });

  const loadMore = (e: MouseEvent) => {
    e.preventDefault();
    if (hasNextPage && !isFetchingNextPage) fetchNextPage();
  };

  const [popupParam, setPopupParam] = useState("");
  const openPopup = (e, str) => {
    e.preventDefault();
    setPopupParam(str);
  };

  return (
    <main className="flex flex-col w-full max-w-screen-xl px-4 mx-auto md:px-12 grow">
      {showRegister ? <MiniRegister /> : null}
      <Categories current={group} />
      {isLoading ? (
        <div className="flex items-center justify-center grow">
          <Spinner className="w-6 h-6" />
        </div>
      ) : error ? (
        <div className="flex items-center justify-center text-red-500 grow">
          {error["message"] || error}
        </div>
      ) : data.pages.length > 0 && data.pages[0].matches?.length > 0 ? (
        <section className="grid gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {data.pages.map((group, gi) => (
            <Fragment key={gi}>
              {group.matches.map(i => (
                <Tile
                  key={i.metadata.metadata.id}
                  data={i}
                  onClick={e =>
                    openPopup(e, i.metadata.metadata.path_lower.slice(1))
                  }
                />
              ))}
            </Fragment>
          ))}
        </section>
      ) : (
        <div className="flex items-center justify-center text-sm grow">
          找不到相關信息
        </div>
      )}

      <Reveal className="my-8 text-center zoom-in">
        {hasNextPage ? (
          <Link
            onClick={loadMore}
            to={"/?page=" + (data.pages?.[data.pages?.length - 1].cursor || "")}
            className="inline-flex items-center gap-2 p-1 px-4 text-sm text-white rounded shadow bg-crimson base-btn"
          >
            {isFetchingNextPage ? (
              <Spinner className="w-4 h-4 border-paper" />
            ) : null}
            显示更多 &hellip;
          </Link>
        ) : null}
      </Reveal>

      <Dialog
        open={!!popupParam}
        onClickClose={() => setPopupParam("")}
        className="bg-parchment rounded w-[90%] max-w-6xl h-[90%] flex flex-col"
      >
        {popupParam ? <Item path={popupParam} showExpand={true} /> : null}
      </Dialog>
    </main>
  );
}
