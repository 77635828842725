import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { ProfileProvider } from "components/ProfileProvider";
import { IconContext } from "react-icons";

import { LazyMotion, domAnimation } from "framer-motion";

import ErrorBoundary from "components/ErrorBoundary";
import LazyLoading from "components/LazyLoading";

import MainLayout from "components/layouts/Main";

//routes
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Details from "./pages/Details";
import Cart from "./pages/Cart";

//lazy loaded routes
const Dashboard = lazySuspense(() => import("./pages/Dashboard"));
const Admin = lazySuspense(() => import("./pages/Admin"));
const AuthResult = lazySuspense(() => import("./pages/AuthResult"));
const Blog = lazySuspense(() => import("./pages/Blog"));
const BlogDetails = lazySuspense(() => import("./pages/BlogDetails"));
const FeedbackSent = lazySuspense(() => import("./pages/FeedbackSent"));

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route path="auth-result" element={<AuthResult />} />
          <Route path="details/*" element={<Details />} />
          <Route path="cart" element={<Cart />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="admin" element={<Admin />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/:id" element={<BlogDetails />} />
          <Route path="feedback-sent" element={<FeedbackSent />} />
        </Route>
      </Routes>
    </Router>
  );
}

const qc = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  return (
    <IconContext.Provider value={{ size: "100%" }}>
      <QueryClientProvider client={qc}>
        <ProfileProvider>
          <LazyMotion features={domAnimation}>
            <AppRoutes />
          </LazyMotion>
        </ProfileProvider>
      </QueryClientProvider>
    </IconContext.Provider>
  );
}

function lazySuspense(fn) {
  const Component = lazy(
    fn, //n => new Promise(ok => setTimeout(() => ok(fn()), 5000))
  );
  return function (props) {
    return (
      <ErrorBoundary>
        <Suspense fallback={<LazyLoading />}>
          <Component {...props} />
        </Suspense>
      </ErrorBoundary>
    );
  };
}

export default App;
