import {
  BsFolder2 as FLDR,
  BsFileEarmark as Generic,
  BsFileEarmarkImage as IMG,
  BsFileEarmarkPlay as VID,
  BsFileEarmarkMusic as AUD,
  BsFileEarmarkRichtext as DOC,
  BsFileEarmarkBarGraph as XLS,
  BsFileEarmarkSlides as PPT,
  BsFileEarmarkPdf as PDF,
  BsFileEarmarkZip as ZIP,
} from "react-icons/bs";

const fmt = {
  img: new Set("gif,jpg,jpeg,png,tiff,webp,avif,bmp,heic".split(",")),
  vid: new Set(
    "rm,vlc,flv,mp4,mov,avi,mpeg,mpg,wmv,asf,webm,divx,ogm".split(","),
  ),
  aud: new Set("ra,mp3,wav,wma,aac,flac,ogg".split(",")),
  doc: new Set("pages,doc,docx,rtf,txt".split(",")),
  xls: new Set("numbers,xls,xlsx,csv".split(",")),
  ppt: new Set("ppt,pptx".split(",")),
  pdf: new Set("pdf".split(",")),
  zip: new Set("zip,rar,7z".split(",")),
};

const extReg = /\.\w{2,10}$/;

export default function FileIcon({ url = "", className = "w-4 h-6" }) {
  const match = extReg.exec(url);
  const ext = match?.[0]?.slice(1).toLowerCase() || "";

  const props = {
    role: "img",
    className,
  };

  if (url.endsWith("/")) {
    return <FLDR {...props} />;
  } else if (fmt.img.has(ext)) {
    return <IMG {...props} />;
  } else if (fmt.vid.has(ext)) {
    return <VID {...props} />;
  } else if (fmt.aud.has(ext)) {
    return <AUD {...props} />;
  } else if (fmt.doc.has(ext)) {
    return <DOC {...props} />;
  } else if (fmt.xls.has(ext)) {
    return <XLS {...props} />;
  } else if (fmt.ppt.has(ext)) {
    return <PPT {...props} />;
  } else if (fmt.pdf.has(ext)) {
    return <PDF {...props} />;
  } else if (fmt.zip.has(ext)) {
    return <ZIP {...props} />;
  } else {
    return <Generic {...props} />;
  }
}
