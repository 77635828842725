import { useState } from "react";
import { m } from "framer-motion";

export default function Disclosure({
  children,
  title,
  defOpen = false,
  onOpen = (o: boolean) => {},
  className = "",
}) {
  const [open, setOpen] = useState(defOpen);
  const handleClick = () => {
    setOpen(!open);
    onOpen(!open);
  };
  return (
    <m.div
      layout
      className={className + " relative overflow-hidden"}
      animate={{ height: open ? "auto" : "15.5rem" }}
    >
      <button
        className="block w-full base-btn hover:underline focus-visible:underline"
        style={{ textAlign: "inherit" }}
        onClick={handleClick}
      >
        {title}
      </button>
      {children}
      {open ? null : (
        <button
          onClick={handleClick}
          className="absolute bottom-0 left-0 w-full flex justify-between items-end pb-2 px-4 text-xl rounded-b-lg bg-gradient-to-t from-white to-transparent h-[4rem] base-btn border-b border-lines"
          style={{ textShadow: "0 0 3px white, 0 0 5px white" }}
        >
          <div>&#x2195;</div>
          <div>&#x2195;</div>
        </button>
      )}
    </m.div>
  );
}
