import Cart from "components/Cart";
import { useHeadTitle } from "utils";

export default function CartPage() {
  useHeadTitle("易密 - 購物車");
  return (
    <main className="flex flex-col grow">
      <Cart hideExpand />
    </main>
  );
}
