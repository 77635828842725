import { useState } from "react";
import Accordion from "./Accordion";

export default function Footer() {
  const [off, setOff] = useState(false);
  const tempDisableBtn = () => {
    setOff(true);
    setTimeout(() => setOff(false), 2000);
  };

  return (
    <footer className="w-full max-w-screen-xl py-2 mx-auto border-t border-dashed border-lines">
      <div className="w-full max-w-2xl px-4 mx-auto my-8 space-y-3">
        <h2 className="text-center">F.A.Q.</h2>
        <Accordion title="下載鏈接將持續多長時間？">
          每個下載鏈接的有效期為 24 小時。
          24小時後您需要再次單擊該鏈接才能再獲得 24 小時。這操作不會收錢。
        </Accordion>
        <Accordion title="付款後我需要等多長時間才能下載文件？">
          下載鏈接應該立即可用。 請打開 “我的資料”
          頁面並在您的購買旁邊查找下載鏈接。
        </Accordion>
        <Accordion title="付款後我應該在哪裡下載我的文件？">
          請打開 “我的資料” 頁面並在您的購買旁邊查找下載鏈接。
        </Accordion>
        <Accordion title="我應該使用什麼播放器來觀看視頻文件？">
          我們建議下載{" "}
          <a
            href="https://www.videolan.org/vlc/index.zh_TW.html"
            className="underline text-crimson"
          >
            VLC
          </a>
          來觀看視頻。它是一個免費且受支持的多媒體播放器。
        </Accordion>
        <Accordion title="退款政策是什麼？">
          由於數字知識不可退還的性質，我們不提供退款。
          我們相信定價是在所售內容價值的合理預期範圍內。
        </Accordion>
        <Accordion title="還有沒答的問題？">
          <form
            action="https://api.web3forms.com/submit"
            method="POST"
            className="flex flex-col gap-2"
            onSubmit={tempDisableBtn}
          >
            <input type="hidden" name="subject" value="易密網消息" />
            <input
              type="hidden"
              name="redirect"
              value="https://koobooks.pages.dev/feedback-sent"
            />
            <input
              type="hidden"
              name="access_key"
              value="24839e2d-a119-4fad-b842-e374ba50bbd2"
            />
            <label className="flex items-center gap-2">
              <div>郵箱</div>
              <input
                type="email"
                name="email"
                placeholder="@"
                required
                className="p-1 border rounded grow bg-stone-50 border-stone-300"
              />
            </label>
            <label className="flex items-center gap-2">
              <div>姓名</div>
              <input
                type="text"
                name="name"
                autoComplete="name"
                placeholder="姓名"
                className="p-1 border rounded grow bg-stone-50 border-stone-300"
              />
            </label>
            <label className="flex items-center gap-2">
              <div>電話</div>
              <input
                type="tel"
                name="phone"
                placeholder="電話"
                className="p-1 border rounded grow bg-stone-50 border-stone-300"
              />
            </label>
            <input
              type="checkbox"
              name="botcheck"
              style={{ display: "none" }}
            />
            <textarea
              name="message"
              required
              placeholder="請輸入你的疑問"
              className="p-1 border rounded bg-stone-50 border-stone-300"
            ></textarea>
            <button
              type="submit"
              className="self-center p-1 px-8 rounded bg-stone-300 active:bg-stone-400 disabled:opacity-50"
              disabled={off}
            >
              提交
            </button>
          </form>
        </Accordion>
      </div>

      <div className="text-center text-2xs">
        為每個持有人保留所有權利 &copy; {new Date().getFullYear()}
      </div>
    </footer>
  );
}
