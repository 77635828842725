import { useParams } from "react-router-dom";
import { useHeadTitle } from "utils";
import Item from "components/Item";

export default function Details() {
  const path = useParams()["*"];
  useHeadTitle("易密 - " + path);

  return <Item path={path} />;
}
