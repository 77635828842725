import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useProfile } from "./ProfileProvider";
import {
  MdMenuBook,
  MdDeleteForever,
  MdOutlineShoppingBag,
} from "react-icons/md";

import { BsArrowsAngleExpand } from "react-icons/bs";

import MiniLogin from "components/MiniLogin";
import { useMutation } from "@tanstack/react-query";
import { sendRequest } from "network";
import Spinner from "./Spinner";

type CartInput = { email: string; password: string; paths: string[] };
type CartResult = { stripe: string };

export default function Cart({
  onClose,
  hideExpand,
}: {
  onClose?: Function;
  hideExpand?: any;
}) {
  const profile = useProfile();
  const total = [...profile.cart.values()].reduce((sum, i) => sum + i, 0) / 100;

  const destUrl = useRef("");

  const { isLoading, error, isSuccess, mutate } = useMutation(
    (p: CartInput) => sendRequest<CartResult>("/api/dropbox-checkout", p),
    {
      onSuccess({ stripe }) {
        destUrl.current = stripe;
        window.location.href = stripe;
      },
    },
  );
  const [showLogin, setShowLogin] = useState(false);

  const handleSubmit = (e?: any) => {
    e?.preventDefault();
    if (!profile.password) {
      setShowLogin(true);
    } else if (destUrl.current) {
      window.location.href = destUrl.current;
    } else {
      mutate({
        email: profile.email,
        password: profile.password,
        paths: [...profile.cart.entries()].map(([name]) => "/" + name),
      });
    }
  };

  return (
    <section className="flex flex-col grow">
      {hideExpand ? null : (
        <Link to="/cart/" className="absolute top-2 left-2 base-btn">
          <BsArrowsAngleExpand className="w-3 h-3 -rotate-90 md:w-4 md:h-4" />
        </Link>
      )}

      <header className="pt-10">
        <h1 className="text-2xl font-bold text-center">購物車</h1>
        <ol className="flex flex-wrap items-center justify-center my-4 text-sm gap-x-2">
          <li className="px-2 py-0.5 rounded-full bg-lines">確認購買</li>
          &rarr;
          <li className="px-2 py-0.5">第三方支付平台</li>
          &rarr;
          <li className="px-2 py-0.5">下載文件</li>
        </ol>
      </header>
      <ul className="flex flex-col self-center w-full max-w-2xl p-4 sm:text-lg grow">
        {profile.cart.size > 0 ? (
          [...profile.cart.entries()].map(([name, cost]) => (
            <li
              key={name}
              className="flex items-start gap-4 pb-2 mb-2 border-b border-dashed border-lines"
            >
              <button
                className="p-0.5 border rounded border-crimson hover:bg-crimson hover:text-white focus-visible:bg-crimson focus-visible:text-white base-btn text-crimson"
                onClick={() => profile.delCart(name)}
              >
                <MdDeleteForever className="w-5" />
              </button>
              <Link
                to={"/details/" + name}
                className="hover:underline focus:underline hover:text-crimson focus:text-crimson"
              >
                {name}
              </Link>
              <div className="text-right grow">${cost / 100}</div>
            </li>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center text-2xl grow text-lines">
            <MdMenuBook className="h-10" />
            吉車
          </div>
        )}
      </ul>
      <footer className="sticky bottom-0 flex flex-col items-center border-t border-dashed bg-gradient-to-b from-paper to-parchment border-lines">
        {showLogin ? (
          <MiniLogin
            className="animate-zoom"
            onLogin={() => setShowLogin(false)}
          />
        ) : null}

        {error ? (
          <div className="px-4 mt-4 text-white rounded bg-crimson error-icon">
            {error["message"] || error}
          </div>
        ) : null}

        <div className="flex items-center self-center justify-between w-full max-w-2xl p-4">
          {onClose ? (
            <button
              className="px-2 border rounded border-lines py-0.5 base-btn hidden sm:block"
              onClick={() => onClose()}
            >
              隱藏
            </button>
          ) : null}
          <strong className="px-4">共 {profile.cart.size} 項</strong>
          <button
            className="flex items-center transition-colors gap-1 px-5 py-0.5 text-white rounded bg-crimson base-btn disabled:bg-lines"
            disabled={!(total > 0) || showLogin || isLoading}
            onClick={handleSubmit}
          >
            {isLoading || isSuccess ? (
              <Spinner className={isSuccess ? "text-paper" : ""} />
            ) : (
              <MdOutlineShoppingBag className="w-5" />
            )}
            付款 HK ${total}
          </button>
        </div>
      </footer>
    </section>
  );
}
