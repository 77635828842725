import { NavLink } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { sendRequest } from "network";

import type { Folder } from "network";
import { useEffect, useState } from "react";

export default function Categories({ current }) {
  const [delayEnable, setEnable] = useState(false);
  useEffect(() => {
    let t = setTimeout(() => setEnable(true), 1000);
    return () => clearTimeout(t);
  }, []);

  const { data, isLoading, error } = useQuery({
    queryKey: ["folders"],
    queryFn: () => sendRequest<Folder>("/api/dropbox-categories"),
    enabled: delayEnable,
  });

  const groups = data?.matches.map(i => i.metadata.metadata);

  const LINK = "p-1 px-3 border rounded-full border-lines hover:bg-white ";

  return (
    <nav className="flex items-center mt-4 mb-8 gap-x-6">
      <h1 className="text-lg">類別</h1>
      <div className="flex flex-wrap items-center gap-2 p-1 text-sm">
        <NavLink
          to="/"
          className={LINK + (!current ? "bg-paper" : "border-dashed")}
        >
          全部
        </NavLink>

        {isLoading ? (
          <aside className="placeholder w-[4ch] rounded-full"></aside>
        ) : error ? (
          <div className="p-1 text-xs text-white bg-red-500 rounded">
            {error["message"] || error}
          </div>
        ) : groups?.length > 0 ? (
          groups.map(i => (
            <NavLink
              key={i.id}
              to={"/?group=" + i.path_display}
              className={
                LINK +
                (current === i.path_display ? "bg-paper" : "border-dashed")
              }
            >
              {i.path_display?.slice(1)}
            </NavLink>
          ))
        ) : null}
      </div>
    </nav>
  );
}
