import { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useProfile } from "./ProfileProvider";
import { sendRequest } from "network";

import Spinner from "./Spinner";

type LoginResult = { id: string; email: string };

export default function MiniLogin({
  onLogin,
  className = "",
}: {
  onLogin?: Function;
  [n: string]: any;
}) {
  const profile = useProfile();

  const [email, setEmail] = useState(profile.email || "");
  const [password, setPassword] = useState("");

  const { isLoading, error, mutate } = useMutation(
    (p: any) => sendRequest<LoginResult>("/api/sys-login", p),
    {
      onSuccess(r) {
        if (r.email) profile.setUser(r.email, password);
        onLogin?.();
      },
    },
  );

  const valid =
    /^[^@\s]+@[^@\s]+\.[^@.\s]+$/.test(email) && password?.length >= 8;

  const handleSubmit = e => {
    e.preventDefault();
    if (valid) mutate({ email, password });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={"flex flex-col items-center p-4 " + className}
    >
      <h2>請登入你的戶口:</h2>
      <div className="flex flex-col self-stretch justify-center gap-2 my-2 sm:flex-row">
        <input
          type="email"
          className="p-0.5 px-2 border rounded min-w-[11rem] grow bg-paper border-lines"
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder="郵箱"
          autoFocus={!email}
        />
        <input
          type="password"
          className="p-0.5 px-2 border rounded min-w-[11rem] grow bg-paper border-lines"
          value={password}
          onChange={e => setPassword(e.target.value)}
          placeholder="密碼"
          autoFocus={!!email}
        />
        <button
          type="submit"
          className="p-0.5 px-2 rounded grow base-btn bg-crimson text-paper disabled:bg-stone-300"
          disabled={(email && !valid) || isLoading}
        >
          {isLoading ? <Spinner className="h-4 mx-auto" /> : "登入"}
        </button>
      </div>
      {error ? (
        <div className="px-2 my-1 mb-4 text-white rounded bg-crimson error-icon">
          {error === "none" || error === "many"
            ? "郵箱或密碼錯"
            : error["message"] || error}
        </div>
      ) : null}
      <Link
        to="/?register=1"
        className="underline text-crimson underline-offset-2"
      >
        沒戶口? 免費註冊
      </Link>
    </form>
  );
}
