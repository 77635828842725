export function sendRequest<T extends Record<string, any>>(
  path: `/${string}`,
  body?: Record<string, any>,
): Promise<T> {
  return fetch(process.env.REACT_APP_BASE_URL + path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    body: body ? JSON.stringify(body) : null,
  })
    .then(res => res.json())
    .then(res => {
      if (res.error) throw res.error;
      return res;
    });
}

type Metadata = {
  metadata: {
    id: string;
    name: string;
    path_display: string;
    path_lower: string;
  };
};
export type Folder = {
  has_more: boolean;
  matches: {
    metadata: Metadata;
  }[];
  error?: string;
};

export type Search = {
  has_more: boolean;
  cursor: string;
  matches: {
    metadata: Metadata;
    screenshot: {
      path: string;
      hash: string;
      exp: number;
    };
    tags: string[];
  }[];
  error?: string;
};

export type Detail = {
  tags: string[];
  screenshots: string[];
  contents: {
    entries: {
      tag: "file" | "folder";
      path_lower: string;
      path_display: string;
      size: number;
    }[];
  };
  error?: string;
};

export type Purchases = {
  has_more: boolean;
  data: {
    id: string;
    payment_intent: {
      id: string;
      amount: number;
      created: number;
      currency: string;
      payment_method: {
        id: string;
        billing_details: {
          email: string;
        };
        card: {
          brand: string;
          exp_month: number;
          exp_year: number;
          last4: string;
          networks: { available: string[] };
        };
        created: number;
        type: "card";
      };
      payment_method_types: string[];
      status: string;
    };
    payment_status: "paid" | "unpaid";
    status: "complete" | "open";
    created: number;
    line_items: {
      data: {
        id: string;
        amount_total: number;
        currency: string;
        description: `/${string}`;
        price: {
          id: string;
          currency: string;
          unit_amount: 5000;
          unit_amount_decimal: string;
        };
        quantity: number;
      }[];
      has_more: boolean;
      url: string;
    };
    amount_total: number;
    payment_method_types: string[];
  }[];
};
