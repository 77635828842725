import { useState, useMemo } from "react";
import { Link } from "react-router-dom";

import LazyImage from "components/LazyImage";

import type { Search } from "network";

type SubItem = Search["matches"][number];

export default function Tile({
  data,
  className = "",
  onClick,
}: {
  data: SubItem;
  [k: string]: any;
}) {
  const info = data?.metadata?.metadata;
  const screenshot = data?.screenshot;

  const tags = useMemo(() => {
    return data?.tags?.reduce((obj, cur) => {
      let [n, v] = cur.split("_", 2);
      obj[n] = v ? v : true;
      return obj;
    }, {} as Record<string, any>);
  }, [data?.tags]);

  return (
    <Link
      key={info?.id}
      to={"/details/" + info?.path_display?.slice(1)}
      className={"flex flex-col group " + className}
      onClick={onClick}
      itemScope
      itemType="http://schema.org/Product"
    >
      <div className="flex items-center justify-center grow">
        {screenshot ? (
          <LazyImage
            src={
              process.env.REACT_APP_BASE_URL +
              "/api/dropbox-go-public-img?path=" +
              encodeURIComponent(screenshot.path) +
              "&hash=" +
              screenshot.hash +
              "&exp=" +
              screenshot.exp +
              "&raw=1"
            }
            alt={info?.path_display}
            className={({ loading, error }) =>
              "max-h-[256px] min-h-[20px] min-w-[20px] max-w-full text-2xs text-black/30 group-hover:scale-105 transition-transform " +
              (loading ? "placeholder" : "box-shadow") +
              (loading || error ? "w-[50%] h-[200px]" : "")
            }
          />
        ) : (
          <div className="flex items-center justify-center w-1/2 h-[200px] text-3xl border border-dashed border-lines text-lines">
            !
          </div>
        )}
      </div>
      <div className="flex items-end justify-center p-2 mt-2 gap-y-1 gap-x-3">
        <div itemProp="name" className="text-center grow">
          {info?.name}
        </div>
        <div
          className="p-0.5 px-2 text-sm bg-crimson text-paper rounded shadow whitespace-nowrap"
          itemProp="price"
          itemScope
          itemType="http://schema.org/Offer"
        >
          HK ${tags.cost / 100 || "-"}
        </div>
      </div>
    </Link>
  );
}
