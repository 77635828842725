import { useEffect } from "react";
import { useHeadTitle } from "utils";

export default function NotFound() {
  useHeadTitle("易密 - 找不到鏈接");
  useEffect(() => {
    const n = document.head.appendChild(document.createElement("meta"));
    n.name = "robots";
    n.content = "noindex";
    return () => n.remove();
  }, []);
  return (
    <main className="flex flex-col items-center justify-center flex-1 min-h-full p-3 text-xl">
      找不到鏈接。 請稍後再試。
    </main>
  );
}
