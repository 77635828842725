import { useState, createContext, useContext } from "react";

type Json = Record<string, any>;

const LS_CART = "yisecret-cart";
const LS_USER = "yisecret-user";
const LS_PASS = "yisecret-pass";

const data = {
  //overridden
  setState(obj: Json) {},

  email: "",
  password: "",
  setUser(email: string, password: string) {
    this.setState({ email, password });
    localStorage[LS_USER] = email || "";
    sessionStorage[LS_PASS] = password || "";
  },

  cart: new Map<string, number>(),
  addCart(s: string, cost: number) {
    //Stripe limit is 100 lines
    if (this.cart.size < 100) {
      this.cart.set(s, cost);
      this.setState({});
      this._saveLS();
    }
  },
  delCart(s: string) {
    this.cart.delete(s);
    this.setState({});
    this._saveLS();
  },
  clearCart() {
    this.cart.clear();
    this.setState({});
    this._saveLS();
  },
  _saveLS() {
    localStorage[LS_CART] = JSON.stringify([...this.cart.entries()]);
  },

  itemLinks: new Map<string, string>(),
};

type Wrapper = { data: Json & typeof data };

export const ProfileContext = createContext<Wrapper>({ data });

export function useProfile() {
  const { data } = useContext(ProfileContext);
  return data;
}

export function ProfileProvider({ children }) {
  const [state, setState] = useState<Wrapper>({ data });

  data.setState = (obj: Json) => {
    Object.assign(data, obj);
    setState({ data });
  };

  return (
    <ProfileContext.Provider value={state}>{children}</ProfileContext.Provider>
  );
}

if (window.localStorage) {
  let s = localStorage[LS_CART];
  if (s) {
    try {
      data.cart = new Map(JSON.parse(s));
    } catch (e) {
      localStorage.removeItem(LS_CART);
    }
  }
  if (window.sessionStorage) {
    let u = localStorage[LS_USER];
    let p = sessionStorage[LS_PASS];
    data.email = u;
    if (u && p) data.password = p;
  }
}
