import { useCallback, useState } from "react";

type State = { loading?: boolean; error?: boolean };

export default function LazyImage({
  src,
  alt = "",
  className,
}: {
  src: string;
  alt?: string;
  className: (o: State) => string;
}) {
  const [state, setState] = useState<State>({ loading: true });
  const pass = useCallback(() => setState({}), []);
  const fail = useCallback(() => setState({ error: true }), []);
  return (
    <img
      alt={alt}
      src={src}
      loading="lazy"
      className={className(state)}
      onLoad={pass}
      onError={fail}
    />
  );
}
