import { useState } from "react";
import { m } from "framer-motion";

export default function Accordion({ children, title }) {
  const [open, setOpen] = useState(false);
  const handleClick = e => {
    e.preventDefault();
    setOpen(i => !i);
  };
  return (
    <dl className="border rounded shadow border-lines">
      <dt className="px-4 py-1.5">
        <button
          className="base-btn hover:underline w-full text-start focus-visible:underline"
          onClick={handleClick}
        >
          {title}
        </button>
      </dt>
      <m.dd
        layout
        className="ml-4 mr-4 border-t border-dashed border-lines overflow-hidden"
        animate={{ height: open ? "auto" : 0 }}
        transition={{ type: "spring", duration: 0.8 }}
      >
        <div className="py-4 pl-4">{children}</div>
      </m.dd>
    </dl>
  );
}
