import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useProfile } from "./ProfileProvider";

import Dialog from "components/Dialog";
import Cart from "components/Cart";

import {
  MdPersonOutline,
  MdOutlineShoppingBag,
  MdOutlineSearch,
  MdOutlineBook,
} from "react-icons/md";
import MiniLogin from "./MiniLogin";

export default function Header() {
  const navigate = useNavigate();
  const profile = useProfile();

  const [text, setText] = useState("");

  const handleSearch = e => {
    e?.preventDefault();
    navigate("/?q=" + text);
  };

  const [showCart, setShowCart] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const clickProfile = () => {
    if (profile.password) {
      navigate("/dashboard");
    } else {
      setShowLogin(true);
    }
  };

  const onLogin = () => {
    setShowLogin(false);
    navigate("/dashboard");
  };

  const BUTTON =
    "p-1 px-1.5 text-xs sm:text-sm focus-visible:text-white rounded-sm focus-visible:bg-crimson hover:text-white hover:bg-crimson transition-colors flex gap-1 items-center";

  return (
    <header className="border-b border-dashed bg-gradient-to-t from-paper to-transparent border-lines">
      <div className="flex flex-col items-stretch justify-between w-full max-w-screen-xl p-4 mx-auto gap-x-1 gap-y-6 whitespace-nowrap sm:pt-12 sm:flex-row">
        <div className="flex items-center justify-between gap-1 grow">
          <Link
            to="/"
            className="text-3xl"
            style={{ fontFamily: "'Yuji Syuku'", letterSpacing: "-0.1em" }}
          >
            易密
          </Link>
          <form
            onSubmit={handleSearch}
            className="flex items-center justify-center gap-1 border-b"
          >
            <MdOutlineSearch className="w-5" />
            <input
              type="text"
              value={text}
              onChange={e => setText(e.target.value)}
              className="text-sm bg-transparent max-w-full w-[12ch] sm:w-[20ch] p-1 focus:outline-2 focus:outline-crimson"
              placeholder="搜索書名..."
            />
            <button
              className="p-0.5 rounded bg-lines hover:bg-crimson focus:bg-crimson focus:text-paper hover:text-paper transition-opacity"
              onClick={handleSearch}
              style={{ opacity: text ? 1 : 0 }}
              disabled={!text}
            >
              <MdOutlineSearch className="w-5" />
            </button>
          </form>
        </div>
        <div className="flex items-stretch justify-center gap-1 sm:gap-1 sm:flex-[1] sm:justify-end">
          <Link to="/blog" className={BUTTON}>
            <MdOutlineBook className="w-5" />
            網誌
          </Link>
          <button className={BUTTON} onClick={clickProfile}>
            <MdPersonOutline className="w-5" />
            我的資料
          </button>
          <button className={BUTTON} onClick={() => setShowCart(true)}>
            <MdOutlineShoppingBag className="w-5" />
            購物車
            {profile.cart.size > 0 ? (
              <aside className="px-1 text-xs text-white rounded-sm bg-crimson animate-zoom">
                {profile.cart.size}
              </aside>
            ) : null}
          </button>
        </div>
      </div>
      <Dialog
        open={showCart}
        onClickClose={() => setShowCart(false)}
        className="bg-parchment rounded-lg w-[90%] max-w-xl mx-auto h-[90%] flex flex-col"
      >
        {showCart ? <Cart onClose={() => setShowCart(false)} /> : null}
      </Dialog>
      <Dialog
        open={showLogin}
        onClickClose={() => setShowLogin(false)}
        className="bg-parchment rounded-lg w-[90%] max-w-lg mx-auto py-5 overflow-hidden"
      >
        {showLogin ? <MiniLogin className="gap-1" onLogin={onLogin} /> : null}
      </Dialog>
    </header>
  );
}
