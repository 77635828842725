import styles from "./Dialog.module.css";

import { useEffect, useRef, useState } from "react";
import type { MouseEvent, AnimationEvent } from "react";
import { useLocation } from "react-router-dom";

export default function Dialog({
  children,
  className = "w-[90%] max-w-6xl h-[90%] flex flex-col",
  open = false,
  onClickClose,
}: {
  onClickClose?: (e: MouseEvent) => void;
  [n: string]: any;
}) {
  const [loaded, setLoaded] = useState(false);
  if (open && !loaded) setLoaded(true);

  const handleMotionEnd = (e: AnimationEvent) => {
    if (e.target === e.currentTarget) {
      if (!open && loaded) setLoaded(false);
    }
  };

  const handleClickBackdrop = (e: MouseEvent) => {
    if (e.target === e.currentTarget) onClickClose(e);
  };

  const location = useLocation();
  const lastLocation = useRef(location);
  useEffect(() => {
    if (lastLocation.current !== location) {
      lastLocation.current = location;
      onClickClose?.({} as any);
    }
  }, [location, onClickClose]);

  return open || loaded ? (
    <aside
      className={
        "z-10 bg-black/50 fixed top-0 left-0 flex flex-col items-center w-full h-full overflow-hidden " +
        (open ? styles.fadein : styles.fadeout)
      }
      onClick={onClickClose ? handleClickBackdrop : null}
      onAnimationEnd={handleMotionEnd}
    >
      <div className="flex-1"></div>
      <div
        className={
          "overflow-auto shadow-xl relative shadow-black/50 " + className
        }
      >
        {children}
      </div>
      {onClickClose ? (
        <button
          className="absolute w-6 h-6 rounded bg-black/20 top-2 right-2 md:top-4 base-btn md:right-4"
          onClick={onClickClose}
        >
          <b className="absolute left-0 w-full h-0.5 rotate-45 -translate-y-1/2 top-1/2 bg-paper"></b>
          <b className="absolute left-0 w-full h-0.5 -rotate-45 -translate-y-1/2 top-1/2 bg-paper"></b>
        </button>
      ) : null}
      <div className="flex-1"></div>
    </aside>
  ) : null;
}
