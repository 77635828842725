import { useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { sendRequest } from "network";
import { useProfile } from "./ProfileProvider";
import Dialog from "components/Dialog";
import Cart from "components/Cart";
import LazyImage from "./LazyImage";

import type { Detail } from "network";

import Spinner from "./Spinner";
import Disclosure from "./Disclosure";
import FileIcon from "./FileIcon";

import { BsArrowsAngleExpand } from "react-icons/bs";
import {
  MdDeleteForever,
  MdOutlineShoppingBag,
  MdMenuBook,
} from "react-icons/md";
import {
  RiVisaLine,
  RiMastercardFill,
  RiAlipayFill,
  RiWechatFill,
} from "react-icons/ri";

const MB = 1024 * 1024;

let lastOpenState = false;

export default function Item({ path, showExpand = false }) {
  const navigate = useNavigate();
  const profile = useProfile();

  const { data, isLoading, error } = useQuery({
    queryKey: ["book", path],
    queryFn: () =>
      sendRequest<Detail>("/api/dropbox-item", {
        path: "/" + path,
      }),
    onSuccess: res => {
      if (res.error === "!accessToken") navigate("/admin");
    },
  });

  const screenshots = useMemo(
    () =>
      data?.screenshots?.map(i =>
        /\?/.test(i) ? i.replace("?", "?raw=1&") : i + "?raw=1",
      ),
    [data?.screenshots],
  );

  const tags = useMemo(() => {
    let obj: Record<string, any> = {};
    return (
      data?.tags?.reduce((obj, cur) => {
        let [n, v] = cur.split("_", 2);
        obj[n] = v ? v : true;
        return obj;
      }, obj) || obj
    );
  }, [data?.tags]);

  const [showCart, setShowCart] = useState(false);

  if (error) {
    return (
      <main className="flex items-center justify-center grow error-icon text-crimson">
        {error["message"] || error}
      </main>
    );
  }

  if (isLoading || !data) {
    return (
      <main className="flex flex-col items-center p-4 grow">
        {showExpand ? (
          <Link
            to={"/details/" + path}
            className="absolute top-2 left-2 base-btn"
          >
            <BsArrowsAngleExpand className="w-3 h-3 -rotate-90 md:w-4 md:h-4" />
          </Link>
        ) : null}
        <h1 className="px-4 pb-3 my-12 text-2xl text-center border-b-2 md:text-3xl border-lines">
          {path || "-"}
        </h1>
        <div className="flex items-center justify-center grow">
          <Spinner className="h-6" />
        </div>
      </main>
    );
  }

  return (
    <main className="w-full max-w-screen-xl p-4 mx-auto">
      {showExpand ? (
        <Link
          to={"/details/" + path}
          className="absolute top-2 left-2 base-btn"
        >
          <BsArrowsAngleExpand className="w-3 h-3 -rotate-90 md:w-4 md:h-4" />
        </Link>
      ) : null}
      <h1 className="flex justify-center">
        <div className="px-4 pb-3 my-12 text-2xl text-center border-b-2 md:text-3xl border-lines">
          {path || "-"}
        </div>
      </h1>
      <div className="flex flex-col items-stretch gap-8 mb-8 md:flex-row md:items-start">
        <div className="flex justify-center grow">
          {screenshots?.length > 0 ? (
            <LazyImage
              src={screenshots[0]}
              alt="小图"
              className={({ loading }) =>
                "max-w-full text-2xs " +
                (loading ? "w-[50%] aspect-square placeholder" : "box-shadow")
              }
            />
          ) : null}
        </div>
        <div className="sticky top-4 md:w-[16rem] min-w-[16rem]">
          <div className="flex flex-col p-4 text-center rounded bg-paper">
            <div>購買和下載</div>
            {profile.cart.has(path) ? (
              <div className="flex gap-1 my-4">
                <button
                  className="flex p-1 px-1.5 items-center justify-center text-sm rounded text-crimson bg-lines base-btn"
                  onClick={() => profile.delCart(path)}
                >
                  <MdDeleteForever className="w-5" />
                  <div>${tags.cost / 100 || "-"}</div>
                </button>
                <Link
                  to="/cart"
                  className="flex items-center justify-center gap-1 p-1 text-white rounded bg-crimson base-btn grow animate-zoom"
                >
                  <MdOutlineShoppingBag className="w-5" />
                  <div>去結賬</div>
                </Link>
              </div>
            ) : (
              <button
                className="p-1 my-4 text-white rounded bg-crimson base-btn"
                onClick={() => profile.addCart(path, tags.cost * 1)}
                disabled={!tags.cost}
                itemScope
                itemType="http://schema.org/Offer"
                itemProp="price"
              >
                HK ${tags.cost / 100 || "-"}
              </button>
            )}
            <aside className="text-xs">
              您的付款將由第三方支付平台 Stripe 處理。
              付款後你會獲得下載文件的鏈接。本店只出售數字化內容不出售實物物品。
            </aside>
            <aside className="flex flex-row justify-center gap-2 mt-4">
              <RiVisaLine className="w-6" />
              <RiMastercardFill className="w-6" />
              <RiAlipayFill className="w-6" />
              <RiWechatFill className="w-6" />
            </aside>
          </div>
        </div>
      </div>

      <Disclosure
        title={<h2 className="py-6 text-2xl text-center base-btn">資料庫</h2>}
        className="w-full max-w-2xl mx-auto my-10"
        defOpen={lastOpenState}
        onOpen={i => (lastOpenState = i)}
      >
        <div className="flex flex-col items-stretch px-2 sm:px-4">
          {data.contents.entries.map(i => (
            <div
              key={i.path_lower}
              className="flex items-start gap-3 pt-2 mt-2 border-t border-lines"
            >
              <FileIcon url={i.size ? i.path_lower : "/"} className="w-6 h-6" />
              <div className="flex-1">
                <div
                  className={
                    "float-right ml-2 whitespace-nowrap " +
                    (i.size > MB ? "text-stone-400" : "text-stone-300")
                  }
                >
                  {i.size ? prettySize(i.size) : null}
                </div>

                {splitPaths(i.path_display.slice(1)).map((s, i, { length }) => (
                  <span
                    key={i}
                    className={i + 1 >= length ? "" : "text-[#aaa]"}
                  >
                    {s}
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Disclosure>

      <div className="px-2 mb-8 text-sm text-center md:text-base">
        <MdMenuBook className="inline-block w-5 h-5 mr-2 -mt-1" />
        本店只出售數字化內容不出售實物物品。
      </div>

      {screenshots?.length > 1
        ? screenshots
            .slice(1)
            .map(i => (
              <LazyImage
                key={i}
                src={i}
                className={({ loading }) =>
                  "mx-auto my-4 " +
                  (loading ? "w-1/2 aspect-square placeholder" : "box-shadow")
                }
              />
            ))
        : null}

      <Dialog
        open={showCart}
        onClickClose={() => setShowCart(false)}
        className="bg-parchment rounded-lg w-[90%] max-w-xl mx-auto h-[90%] flex flex-col"
      >
        {showCart ? <Cart onClose={() => setShowCart(false)} /> : null}
      </Dialog>
    </main>
  );
}

function prettySize(n: number) {
  const factor = 1024;
  if (n < factor) return n + " B";
  n /= factor;
  if (n < factor) return Math.round(n) + " KB";
  n /= factor;
  if (n < factor) return Math.round(n) + " MB";
}

function splitPaths(url: string) {
  let pos = url.lastIndexOf("/");
  return pos < 0 ? [null, url] : [url.slice(0, pos + 1), url.slice(pos + 1)];
}
